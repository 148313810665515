import { LocalizationString, WarningIcon } from '@celito.clients/assets';
import {
  ActionTypeEnum,
  DocumentDownloadActionEnum,
  ObjectEnum,
  ROUTES,
} from '@celito.clients/enums';
import {
  useActiveModule,
  useObjectDefinition,
  useQueryParams,
  useUpdateInitiationConfirmationModal,
} from '@celito.clients/hooks';
import {
  actionColumnListViewStyles,
  DelegationModal,
  LifeCycleStageUserActionsPermissionEnum,
  triggerWorkflow,
} from '@celito.clients/list-view-engine';
import { ActionContext, ModalContext } from '@celito.clients/provider';
import {
  files,
  getDocumentCertificateMetadata,
  getDocumentData,
} from '@celito.clients/services';
import {
  ConfirmDialog,
  FluentIconNames,
  HeaderActionButtonsContainer,
  Icon,
  Menu,
  useBreadcrumbs,
  UserActionPopup,
} from '@celito.clients/shared';
import {
  DownloadableFileData,
  ObjectActionDefinition,
} from '@celito.clients/types';
import {
  createTestAttribute,
  errorToast,
  infoToast,
  raiseErrorToast,
  requiresVersionParam,
  successToast,
} from '@celito.clients/utils';
import {
  Button,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from '@fluentui/react-components';
import { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';

import { useScreenContext } from '../context';
import { useRecordData } from '../hooks/use-record-data';
import { getStyles as DocsStyles } from '../styles';

export const ContextMenu = () => {
  const {
    recordName,
    version,
    userActions: actions,
    task,
  } = useScreenContext();
  const { addBreadcrumb, replaceLastBreadcrumb } = useBreadcrumbs();
  const { data: response, isLoading } = useRecordData();
  const { data: objectAttributeDefinition } = useObjectDefinition({
    objectName: ObjectEnum.CONTROLLED_DOCUMENT,
    version,
    recordName,
    queryOptions: {
      enabled: false,
    },
  });
  const [openDelegateModal, setOpenDelegateModal] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [respectiveUserActionRes, setRespectiveUserActionRes] =
    useState<ObjectActionDefinition>();
  const [isShowText, setIsShowText] = useState(false);
  const newStyles = DocsStyles();
  const navigate = useNavigate();
  const { openModal } = useContext(ModalContext);
  const { onClickCheckOutAction } = useContext(ActionContext);
  const { getSearchParams } = useQueryParams();
  const searchParams = getSearchParams();
  const updateInitiationConfirmationProps =
    useUpdateInitiationConfirmationModal({
      objectName: ObjectEnum.CONTROLLED_DOCUMENT,
    });
  const activeModule = useActiveModule();
  const {
    showUpdateInititationConfirmationModal,
    setShowUpdateInititationConfirmationModal,
    handleOnUpdateInitiationConfirmation,
    handleOnUpdateInitiate,
    loading,
  } = updateInitiationConfirmationProps;

  const callUserActions = async (action: ObjectActionDefinition) => {
    if (action?.properties?.fields?.length) {
      if (action.actionType === ActionTypeEnum.CHECK_IN) {
        setIsShowText(false);
        setIsConfirmDialogOpen(true);
        setRespectiveUserActionRes(action);
        return;
      }
      if (action.actionType === ActionTypeEnum.CANCEL) {
        setIsShowText(true);
        setIsConfirmDialogOpen(true);
        setRespectiveUserActionRes(action);
        return;
      }
      openEsignModal(response, action);
      return;
    }
    if (
      [
        ActionTypeEnum.TRIGGER_WORKFLOW,
        ActionTypeEnum.CHECK_OUT,
        ActionTypeEnum.CANCEL_CHECK_OUT,
      ].includes(action.actionType) &&
      recordName &&
      response?.version
    ) {
      callTriggerWorkflow(
        action.name,
        recordName,
        response?.version,
        action.actionType
      );
      return;
    }
    if (action.actionType === ActionTypeEnum.VERSION_HISTORY) {
      const url = `../${ROUTES.VERSIONHISTORY.replace(
        ':documentId',
        response?.document?.documentId ?? ''
      )}?objectName=${ObjectEnum.CONTROLLED_DOCUMENT}&recordName=${recordName}`;

      navigate(
        addBreadcrumb({
          path: url,
          label: action.label,
        })
      );
      return;
    }
    if (
      action.actionType === ActionTypeEnum.DOWNLOAD ||
      action.actionType === ActionTypeEnum.DOWNLOAD_RAW
    ) {
      const downloadAction =
        action.actionType === ActionTypeEnum.DOWNLOAD
          ? DocumentDownloadActionEnum.Download
          : DocumentDownloadActionEnum.DownloadRaw;

      const documentId = response?.document?.documentId;

      if (!documentId) {
        errorToast({ message: 'Document not found' });
        return;
      }

      await files.downloadFile(
        getDocumentData(
          documentId,
          ObjectEnum.CONTROLLED_DOCUMENT,
          recordName,
          response?.version, // earlier it was document?.version. Now we will have one version which is record's version
          downloadAction,
          response?.version
        ),
        handleDownload
      );

      return;
    }
    if (action.actionType === ActionTypeEnum.EDIT) {
      const url =
        window.location.pathname.replace(
          'view/custom/controlled_document_detail_view__a',
          'edit/custom/controlled_document_edit_view__a'
        ) +
        '?' +
        createSearchParams({
          ...(requiresVersionParam(
            (response?.version ?? '') as string,
            response?.documentStatus as string
          )
            ? { version: response?.version ?? '' }
            : {}),
          ...(searchParams.taskName ? { taskName: searchParams.taskName } : {}),
        });

      const newurl = replaceLastBreadcrumb({
        path: url,
        label: response?.label || response?.title || response?.name || '',
      });

      navigate(newurl, {
        replace: true,
      });
    }

    if (action.actionType === ActionTypeEnum.DOWNLOAD_CERTIFICATE) {
      if (!recordName) {
        errorToast({ message: 'Document certificate not found' });
        return;
      }

      infoToast({ message: 'Downloading document certificate...' });

      try {
        const documentData = await getDocumentCertificateMetadata(recordName);

        handleDownload(documentData);
      } catch (_error) {
        raiseErrorToast(_error);
      }

      return;
    }

    if (action.actionType === ActionTypeEnum.DELEGATE_TASK) {
      setOpenDelegateModal(true);
      return;
    }
    if (
      action.actionType?.toLowerCase() === 'list' &&
      recordName &&
      ObjectEnum.CONTROLLED_DOCUMENT
    ) {
      const url = `../${action.actionType?.toLowerCase()}/${
        action.viewName
      }?recordname=${recordName}&objectname=${ObjectEnum.CONTROLLED_DOCUMENT}`;
      navigate(
        addBreadcrumb({
          path: url,
          label: action.label,
        })
      );
      return;
    }
    if (action.actionType === ActionTypeEnum.UPDATE) {
      handleOnUpdateInitiate(response ?? {}, action.viewName);
      setShowUpdateInititationConfirmationModal(true);

      return;
    }
  };

  const handleDownload = (documentData: DownloadableFileData) => {
    try {
      if (documentData?.fileBuffer) {
        files.downloadFileBuffer({
          data: documentData.fileBuffer.data,
          label: documentData.label,
          mimeType: documentData.mimeType,
        });
      }
      if (documentData?.certificateBuffer) {
        const parts = documentData.label.split('.');
        parts.pop(); // popping file extension
        const name = `${parts.join('.')} Approval Page.pdf`;
        files.downloadFileBuffer({
          data: documentData.certificateBuffer.data,
          label: name,
          mimeType: documentData.mimeType,
        });
      }
    } catch (error) {
      errorToast({ message: `${error}` });
    }
  };

  const callTriggerWorkflow = async (
    actionName: string,
    recordName: string,
    version: string,
    actionType: string
  ) => {
    try {
      if (
        actionType === ActionTypeEnum.CHECK_OUT ||
        actionType === ActionTypeEnum.CANCEL_CHECK_OUT
      ) {
        infoToast({
          message:
            actionType === ActionTypeEnum.CHECK_OUT
              ? LocalizationString.CHECK_OUT_INITIATION
              : LocalizationString.CANCEL_CHECK_OUT_INITIATION,
        });
      }
      await triggerWorkflow(actionName, recordName, version);
      if (
        actionType === ActionTypeEnum.CHECK_OUT ||
        actionType === ActionTypeEnum.CANCEL_CHECK_OUT
      ) {
        successToast({
          message:
            actionType === ActionTypeEnum.CHECK_OUT
              ? LocalizationString.CHECK_OUT
              : LocalizationString.CANCEL_CHECK_OUT,
        });
        if (actionType === ActionTypeEnum.CHECK_OUT) {
          const documentId = response?.document?.documentId;

          if (!documentId) {
            errorToast({ message: 'Document not found' });
            return;
          }

          await files.downloadFile(
            getDocumentData(
              documentId,
              ObjectEnum.CONTROLLED_DOCUMENT,
              recordName,
              requiresVersionParam(
                response?.version ?? '',
                response?.documentStatus ?? ''
              )
                ? response?.version
                : '',
              DocumentDownloadActionEnum.DownloadRaw
            ),
            handleDownload
          );
        }
        onClickCheckOutAction();
      }
    } catch (_error) {
      raiseErrorToast(_error);
    }
  };
  const closeDelegateModal = () => {
    setOpenDelegateModal(false);
  };
  const openEsignModal = (
    recordData?: Record<string, unknown>,
    contextMenuAction?: Record<string, unknown>,
    taskPayload?: Record<string, unknown>
  ) => {
    openModal(
      '',
      taskPayload,
      activeModule?.link,
      recordData,
      contextMenuAction
    );
  };

  const delegationModalRowItem = useMemo(
    () => ({
      ...task,
      key: task?.name ?? Math.random(),
    }),
    [task]
  );
  const actionColumnStyles = actionColumnListViewStyles();

  const disabled = !actions?.length || isLoading;

  return (
    <>
      <HeaderActionButtonsContainer>
        <Menu positioning={'below-end'}>
          <MenuTrigger>
            <Button
              className={newStyles.menuButton}
              data-testid={`button-more-action`}
              disabled={disabled}
            >
              <Icon iconName="MoreHorizontal24Regular" />
            </Button>
          </MenuTrigger>
          <MenuPopover>
            <MenuList className={actionColumnStyles.menuList}>
              {actions?.map((action, index) => (
                <MenuItem
                  key={index}
                  disabled={
                    action?.permission ===
                    LifeCycleStageUserActionsPermissionEnum.VIEW
                  }
                  data-testid={`context-menu-option-${createTestAttribute(
                    action.label
                  )}`}
                  className={actionColumnStyles.actionMenuItem}
                  onClick={() => callUserActions(action)}
                >
                  <div className={actionColumnStyles.itemContent}>
                    <Icon iconName={action.iconName as FluentIconNames} />
                    {action.label}
                  </div>
                </MenuItem>
              ))}
            </MenuList>
          </MenuPopover>
        </Menu>
        <DelegationModal
          isOpen={openDelegateModal}
          onClose={closeDelegateModal}
          rowItem={delegationModalRowItem}
          objectName={ObjectEnum.CONTROLLED_DOCUMENT}
        />
        {respectiveUserActionRes && (
          <UserActionPopup
            open={isConfirmDialogOpen}
            onConfirmClicked={() => {}}
            onCancelClicked={() => {
              setRespectiveUserActionRes(undefined);
              setIsConfirmDialogOpen(false);
            }}
            title={respectiveUserActionRes?.label}
            primaryButtonText={
              respectiveUserActionRes.actionType === ActionTypeEnum.CANCEL
                ? LocalizationString.YES
                : LocalizationString.CHECK_IN
            }
            secondaryButtonText={
              respectiveUserActionRes.actionType === ActionTypeEnum.CANCEL
                ? LocalizationString.NO
                : LocalizationString.CANCEL
            }
            attributeConfig={objectAttributeDefinition!}
            userActionData={respectiveUserActionRes}
            recordName={recordName!}
            version={version!}
            isTextRequired={isShowText}
            recordDetails={response}
          ></UserActionPopup>
        )}
      </HeaderActionButtonsContainer>
      <ConfirmDialog
        dataTestId={`${createTestAttribute(
          LocalizationString.UPDATE_INITIATION_CONFIRMATION
        )}-modal`}
        open={showUpdateInititationConfirmationModal}
        onCancelClicked={() => setShowUpdateInititationConfirmationModal(false)}
        onConfirmClicked={handleOnUpdateInitiationConfirmation}
        primaryButtonText={LocalizationString.YES}
        secondaryButtonText={LocalizationString.NO}
        title={LocalizationString.UPDATE_INITIATION_CONFIRMATION}
        iconSrc={WarningIcon}
        description={LocalizationString.UPDATE_INITIATION_CONFIRMATION_MSG.replace(
          '{objectName}',
          objectAttributeDefinition?.label ?? 'record'
        )}
        isPrimaryButtonLoading={loading}
      />
    </>
  );
};
