import {
  ButtonTypes,
  CreationRenderTypeEnum,
  FormEngineModeTypeEnum,
} from '@celito.clients/enums';
import { useLayout } from '@celito.clients/hooks';
import {
  CustomButton,
  Icon,
  IconButton,
  useBreadcrumbs,
} from '@celito.clients/shared';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
} from '@fluentui/react-components';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import FormEngine from '../../form-engine';
import { FormTypeRendererStyles } from './form-type-renderer.styles';

interface FormRendererProps {
  viewType: CreationRenderTypeEnum;
  viewName?: string;
  route: string;
  buttonTitle: string;
  buttonType?: ButtonTypes;
  mode?: FormEngineModeTypeEnum;
}

export const FormTypeRenderer = ({
  viewType = CreationRenderTypeEnum.Page,
  viewName,
  route,
  buttonTitle,
  buttonType = ButtonTypes.Primary,
  mode = FormEngineModeTypeEnum.Create,
}: FormRendererProps) => {
  const classes = FormTypeRendererStyles();
  const { addBreadcrumb } = useBreadcrumbs();
  const [isOpen, setIsOpen] = useState(false);
  const { configureLayout, headerTitle } = useLayout();
  const initialHeaderTitleRef = useRef(headerTitle);
  const isHeaderTitleChanged = headerTitle !== initialHeaderTitleRef.current;
  const oldHeaderTitle = isHeaderTitleChanged
    ? initialHeaderTitleRef.current
    : '';

  const onOpenChange = (open: boolean) => {
    configureLayout({
      pageTitle: '',
      enablePadding: false,
      headerTitle: oldHeaderTitle,
    });
    setIsOpen(open);
  };

  switch (viewType) {
    case 'modal':
      return (
        <Dialog open={isOpen}>
          <DialogTrigger disableButtonEnhancement>
            <CustomButton
              buttonTitle={buttonTitle}
              buttonType={buttonType}
              onClick={() => onOpenChange(!isOpen)}
            />
          </DialogTrigger>
          <DialogSurface className={classes.dialogSurface}>
            <DialogBody style={{ height: '100%', gap: 0 }}>
              <DialogTitle className={classes.dialogTitle}>
                {headerTitle}
                <IconButton
                  appearance="subtle"
                  icon={<Icon iconName="Dismiss24Regular" />}
                  onClick={() => onOpenChange(false)}
                />
              </DialogTitle>
              <DialogContent>
                <FormEngine
                  viewName={viewName}
                  mode={mode}
                  onCancel={() => onOpenChange(false)}
                  onSubmit={() => onOpenChange(false)}
                />
              </DialogContent>
            </DialogBody>
          </DialogSurface>
        </Dialog>
      );
    case 'panel':
      return (
        <div>
          <Drawer type="overlay" open={isOpen} size="large" position="end">
            <DrawerHeader style={{ padding: 0 }}>
              <DrawerHeaderTitle
                className={classes.dialogTitle}
                action={
                  <IconButton
                    appearance="subtle"
                    icon={<Icon iconName="Dismiss24Regular" />}
                    onClick={() => onOpenChange(false)}
                  />
                }
              >
                {headerTitle}
              </DrawerHeaderTitle>
            </DrawerHeader>

            <DrawerBody style={{ padding: 0 }}>
              <FormEngine
                viewName={viewName}
                mode={mode}
                onCancel={() => onOpenChange(false)}
                onSubmit={() => onOpenChange(false)}
              />
            </DrawerBody>
          </Drawer>
          <CustomButton
            buttonTitle={buttonTitle}
            buttonType={buttonType}
            onClick={() => onOpenChange(!isOpen)}
          />
        </div>
      );

    default:
      return (
        <Link
          to={addBreadcrumb({
            label: buttonTitle,
            path: route,
          })}
          style={{ textDecoration: 'none' }}
        >
          <CustomButton buttonTitle={buttonTitle} buttonType={buttonType} />
        </Link>
      );
  }
};
