import { IField } from '../services/apiServices';
export const getResultCardContent = (
  result: IField[],
  moduleNameProps: string
) => {
  const moduleName = moduleNameProps;
  let detailViewName = null;
  let resultLabel = null;
  let resultTitle = null;
  let resultName = null;
  let modifiedAtDate = null;
  let createdAtDate = null;
  let version = null;
  const cardHeaderContent: Record<string, string> = {};
  const cardBodyContent: Record<string, string> = {};

  for (const field of result) {
    const {
      columnKey,
      columnValue,
      columnName,
      canUseInHyperLink,
      detailViewName: detailViewNameProps,
    } = field;

    detailViewName = detailViewNameProps;

    switch (columnKey) {
      case 'label':
        resultLabel = columnValue ?? resultLabel;
        break;
      case 'title':
        resultTitle = columnValue ?? resultTitle;
        break;
    }

    switch (columnKey) {
      case 'modifiedAtUtc':
        modifiedAtDate = columnValue ?? modifiedAtDate;
        break;
      case 'createdAtUtc':
        createdAtDate = columnValue ?? createdAtDate;
        break;
      case 'name':
        resultName = columnValue ?? resultName;
        break;
      default:
        if (columnName && columnValue) {
          const targetContent = canUseInHyperLink
            ? cardHeaderContent
            : cardBodyContent;
          targetContent[columnName] = columnValue;
        }
        break;
    }

    if (columnKey === 'version') version = columnValue ?? version;
  }

  return {
    moduleName,
    detailViewName,
    resultLabel,
    resultTitle,
    resultName,
    modifiedAtDate,
    createdAtDate,
    version,
    cardHeaderContent,
    cardBodyContent,
  };
};
