import { ROUTES } from '@celito.clients/enums';
import { actionColumnListViewStyles } from '@celito.clients/list-view-engine';
import { downloadTrainingCertificate } from '@celito.clients/services';
import {
  ExportModal,
  FluentIconNames,
  Icon,
  Menu,
  useBreadcrumbs,
} from '@celito.clients/shared';
import { raiseErrorToast } from '@celito.clients/utils';
import {
  Button,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from '@fluentui/react-components';
import { RowItem } from 'libs/shared/src/lib/grid-view-new/src/types';
import { useState } from 'react';
import { useNavigate } from 'react-router';

interface IActionItem {
  label?: string;
  onActionClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  iconName?: string;
  type: string;
}

interface ActionColumnProps {
  dataTestId?: string;
  item: RowItem;
}

export const ActionColumn = ({
  dataTestId,
  item,
}: ActionColumnProps): JSX.Element | null => {
  const styles = actionColumnListViewStyles();
  const { addBreadcrumb } = useBreadcrumbs();
  const navigate = useNavigate();

  const [showExportModal, setShowExportModal] = useState(false);

  const onMenuItemClick = (type: string, rowData: RowItem) => {
    if (type === 'viewTraining') {
      const url = `../${ROUTES.TAKING_ASSINGMENTS.replace(
        ':name',
        rowData.assignmentName as string
      )}`;
      navigate(
        addBreadcrumb({
          path: url,
          label: rowData.label,
        })
      );
    } else if (type === 'viewAuditLogs') {
      const url = `../list/ul_activity_log_list_view__a?recordname=${rowData.assignmentName}&objectname=training_assignment__a`;
      navigate(addBreadcrumb({ path: url, label: rowData.label }));
    } else if (type === 'viewCertificate') {
      setShowExportModal(true);
      downloadTrainingCertificate(rowData.assignmentName as string)
        .catch((_error) => {
          raiseErrorToast(_error);
        })
        .finally(() => {
          setShowExportModal(false);
        });
    }
  };

  const menuProps: IActionItem[] = [
    {
      type: 'viewAuditLogs',
      label: 'Audit Logs',
      iconName: 'Document20Regular' as FluentIconNames,
      onActionClick: (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        onMenuItemClick('viewAuditLogs', item);
      },
    },
  ];

  if (item.assignmentName) {
    menuProps.push({
      type: 'viewTraining',
      label: 'View Training',
      iconName: 'Eye20Regular' as FluentIconNames,
      onActionClick: (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        onMenuItemClick('viewTraining', item);
      },
    });
  }

  if (item.status === 'Complete') {
    menuProps.push({
      type: 'viewCertificate',
      label: 'View Certificate',
      onActionClick: (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        onMenuItemClick('viewCertificate', item);
      },
      iconName: 'Certificate20Regular' as FluentIconNames,
    });
  }

  if (menuProps.length === 0 || !item.dueDate) {
    return null;
  }

  return (
    <div data-testid={dataTestId}>
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <Button
            appearance="subtle"
            icon={<Icon iconName="MoreHorizontal24Regular" />}
            onClick={(e) => e.stopPropagation()}
          />
        </MenuTrigger>

        <MenuPopover className={styles.menuPopover}>
          <MenuList className={styles.menuList}>
            {menuProps.map((menuItem) => {
              return (
                <MenuItem
                  key={menuItem.label}
                  onClick={menuItem.onActionClick}
                  className={styles.actionMenuItem}
                >
                  <div className={styles.itemContent}>
                    <Icon iconName={menuItem.iconName as FluentIconNames} />
                    {menuItem.label}
                  </div>
                </MenuItem>
              );
            })}
          </MenuList>
        </MenuPopover>
      </Menu>
      {showExportModal && (
        <ExportModal onClose={() => setShowExportModal(false)} />
      )}
    </div>
  );
};
