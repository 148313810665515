import { LocalizationString } from '@celito.clients/assets';
import { AttributeTypeEnum, ROUTES } from '@celito.clients/enums';
import {
  useCloseOnScroll,
  useEffectWithDependencyMonitor,
  useLayout,
  useQueryParams,
} from '@celito.clients/hooks';
import {
  ExportItem,
  FilterableColumn,
  getFiltersFromQueryParams,
  getNumberOfFilters,
  ListViewQueryParams,
  setFiltersInQueryParams,
  SortConfig,
} from '@celito.clients/list-view-engine';
import { useBreadcrumbs } from '@celito.clients/shared';
import { ObjectAttributeDefinition } from '@celito.clients/types';
import {
  isObjectEmpty,
  raiseErrorToast,
  successToast,
} from '@celito.clients/utils';
import {
  getIsSortedAscending,
  getSortOrder,
  isReferenceSortSupported,
} from 'libs/list-view-engine/src/lib/utils/sort.util';
import { IColumn, RowItem } from 'libs/shared/src/lib/grid-view-new/src/types';
import { RulesComponentListViewFilter } from 'libs/shared/src/lib/rules-component/types/rules-component.types';
import { isString } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { match } from 'ts-pattern';

import { ViewTypes } from './enums/enums';
import {
  getAssignmentViewData,
  getAssignmentViewExportData,
  getCourseViewData,
  getCourseViewExportData,
  getUserViewData,
  getUserViewExportData,
} from './services';
import { IColumns, MatrixView } from './training-matrix.model';
import { TrainingMatrixVew } from './training-matrix.view';
import {
  getAssignmentViewColumns,
  getAssignmentViewRow,
  getCourseViewColumns,
  getCourseViewRow,
  getCustomAttributeDefinations,
  getUserViewColumns,
  getUserViewGroupNames,
  getUserViewRow,
} from './utils';
import { getSelectedViewFromUrl } from './utils/view-selector-menu';

const PAGE_LIMIT = 10;
const USER_VIEW_PAGE_LIMIT = 5;

export const TrainingMatrixController: React.FC = () => {
  const navigate = useNavigate();
  const { configureLayout } = useLayout();
  const abortController = useRef(new AbortController());
  const { getSearchParams, setUrlParams, clearUrlParams } = useQueryParams();
  const queryParamFilters = getFiltersFromQueryParams(getSearchParams);
  const params = getSearchParams();
  const viewName = params[ListViewQueryParams.FilterView];
  const { addBreadcrumb } = useBreadcrumbs();

  const [selectedView, setSelectedView] = useState<MatrixView>(
    getSelectedViewFromUrl(viewName as ViewTypes)
  );

  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  const [filters, setFilters] = useState<RulesComponentListViewFilter[]>(
    queryParamFilters ? [queryParamFilters] : []
  );
  const [columns, setColumns] = useState<IColumns[]>([]);
  const [numberOfFilters, setNumberOfFilters] = useState(
    getNumberOfFilters(queryParamFilters)
  );
  const [sortConfig, setSortConfig] = useState<SortConfig | undefined>();
  const [rowData, setRowData] = useState<RowItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalItems, setTotalItems] = useState(PAGE_LIMIT);
  const [groupNames, setGroupNames] = useState<string[] | undefined>(undefined);
  const [menuOpen, setMenuOpen] = useState(false);
  const [userName, setUserName] = useState<string | undefined>(undefined);
  const [isUserViewPanelOpen, setIsUserViewPanelOpen] = useState(false);
  const [resetFilterPanel, setResetFilterPanel] = useState(false);
  const [resetColumnSizes, setResetColumnSizes] = useState(false);

  const pageLimit =
    selectedView.value === ViewTypes.USER_VIEW
      ? USER_VIEW_PAGE_LIMIT
      : PAGE_LIMIT;

  useEffect(() => {
    configureLayout({
      pageTitle: '',
      headerTitle: LocalizationString.TRAINING_MATRIX,
    });
    getNewColumns(selectedView);
  }, []);

  useEffectWithDependencyMonitor(
    (changedDeps) => {
      setRowData([]);
      if (Object.keys(changedDeps).includes('filters')) {
        fetchRowData(filters, 1, sortConfig);
      } else fetchRowData(filters, currentPageNumber, sortConfig);

      return () => {
        abortController.current.abort();
        abortController.current = new AbortController();
      };
    },
    [filters, sortConfig],
    ['filters', 'sortConfig']
  );

  const updateSelectedView = (viewOption: MatrixView) => {
    if (viewOption === selectedView) return;
    setIsLoading(true);
    setResetFilterPanel(true);
    setResetColumnSizes(true);
    setSelectedView(viewOption);
    setNumberOfFilters(0);
    clearUrlParams(ListViewQueryParams.Filters);
    setSelectedViewInUrl(viewOption);
    setIsFilterPanelOpen(false);
    getNewColumns(viewOption);
    setSortConfig(undefined);
    setCurrentPageNumber(1);
  };

  const setSelectedViewInUrl = (viewOption: MatrixView) => {
    if (viewOption.isDefault) clearUrlParams(ListViewQueryParams.FilterView);
    else setUrlParams(ListViewQueryParams.FilterView, viewOption.value);
  };

  const getNewColumns = (viewOption: MatrixView) => {
    if (viewOption.value === ViewTypes.COURSE_VIEW) {
      setColumns(getCourseViewColumns(onColumnClick, onUserView));
    } else if (viewOption.value === ViewTypes.ASSIGNMENT_VIEW) {
      setColumns(getAssignmentViewColumns(onColumnClick));
    } else {
      setColumns(getUserViewColumns(onColumnClick));
    }
  };

  const onOneTimeAssignmentButtonClick = () => {
    const url = addBreadcrumb({
      path: `../${ROUTES.ADD_ONE_TIME_ASSIGNMENT}`,
      label: LocalizationString.ADD_ONE_TIME_ASSIGNMENT,
    });
    navigate(url);
  };

  const exportFile = async (exportItemData: ExportItem) => {
    if (selectedView.value) {
      const response = await match(selectedView.value)
        .with(ViewTypes.ASSIGNMENT_VIEW, () =>
          getAssignmentViewExportData(
            exportItemData.data.fileType,
            filters[0],
            sortConfig
          )
        )
        .with(ViewTypes.COURSE_VIEW, () =>
          getCourseViewExportData(
            exportItemData.data.fileType,
            filters,
            sortConfig
          )
        )
        .with(ViewTypes.USER_VIEW, () =>
          getUserViewExportData(
            exportItemData.data.fileType,
            filters[0],
            sortConfig
          )
        )
        .exhaustive();

      if (isString(response.data.message)) {
        successToast({ message: response.data.message });
      }
    }
  };

  const handleFilterButtonClick = () => {
    setIsFilterPanelOpen((prevState) => !prevState);
  };

  const closeFilterPanel = () => {
    setIsFilterPanelOpen(false);
  };

  useCloseOnScroll(setMenuOpen);

  const applyFilters = useCallback(
    (newFilters: RulesComponentListViewFilter, doApiCall = false) => {
      if (!newFilters && filters?.length === 0 && !doApiCall) {
        return;
      }
      setFilters([newFilters]);
      // Set filters in query params
      if (isObjectEmpty(newFilters)) {
        clearUrlParams(ListViewQueryParams.Filters);
      } else {
        setFiltersInQueryParams(newFilters, setUrlParams);
      }
      setNumberOfFilters(getNumberOfFilters(newFilters));
      setCurrentPageNumber(1);
    },
    [filters, clearUrlParams, setUrlParams]
  );

  const customObjectAttributeDefinitions = getCustomAttributeDefinations(
    selectedView,
    columns
  );

  const filterableColumns: FilterableColumn[] =
    customObjectAttributeDefinitions.objectAttributeDefinitions.map(
      (col: ObjectAttributeDefinition) => {
        if (
          col.dataType === AttributeTypeEnum.Reference &&
          col.attributeNameToFilter
        ) {
          return {
            attributeNameToFilter: col.attributeNameToFilter,
            name: col.name,
            label: col.label,
          } as FilterableColumn;
        }
        return { name: col.name };
      }
    );

  const handleSubRowToggle = useCallback((itemKey: string | number) => {
    setRowData((prevItems) =>
      prevItems.map((item) => {
        if (item.key === itemKey) {
          return {
            ...item,
            isSubRowOpen: !item.isSubRowOpen,
          };
        }
        return item;
      })
    );
  }, []);

  const fetchRowData = useCallback(
    async (
      filterConfig: RulesComponentListViewFilter[],
      pageNumber = 1,
      sortConfig?: SortConfig
    ) => {
      setIsLoading(true);

      try {
        if (selectedView.value === ViewTypes.ASSIGNMENT_VIEW) {
          const assignmentData = await getAssignmentViewData(
            {
              page: pageNumber,
              pageLimit: PAGE_LIMIT,
            },
            {
              filters: filterConfig[0],
              sortConfig: sortConfig,
            },
            abortController.current.signal
          );
          setTotalItems(assignmentData.total);
          const rowData = getAssignmentViewRow(assignmentData.data);
          setRowData(rowData);
          setGroupNames(undefined);
          setIsLoading(false);
        } else if (selectedView.value === ViewTypes.COURSE_VIEW) {
          const courseData = await getCourseViewData(
            {
              page: pageNumber,
              pageLimit: PAGE_LIMIT,
            },
            {
              filters: filterConfig[0],
              sortConfig: sortConfig,
            },
            abortController.current.signal
          );
          setTotalItems(courseData.total);
          const rowData = getCourseViewRow(courseData.data, handleSubRowToggle);
          setRowData(rowData);
          setGroupNames(undefined);
          setIsLoading(false);
        } else {
          const userData = await getUserViewData(
            {
              page: pageNumber,
              pageLimit: USER_VIEW_PAGE_LIMIT,
            },
            {
              filters: filterConfig[0],
              sortConfig: sortConfig,
            },
            abortController.current.signal
          );
          setTotalItems(userData.total);
          const groupNames = getUserViewGroupNames(userData.data);
          setGroupNames(groupNames);
          const rowData = getUserViewRow(userData.data);
          setRowData(rowData);
          setIsLoading(false);
        }
      } catch (_error) {
        if ((_error as Error).name === 'CanceledError') {
          return;
        } else {
          setIsLoading(false);
          raiseErrorToast(_error);
        }
      }
    },
    [selectedView, handleSubRowToggle]
  );

  const handlePageChange = (selectedPage: number) => {
    setCurrentPageNumber(selectedPage + 1);
    setRowData([]);
    fetchRowData(filters, selectedPage + 1, sortConfig);
  };

  const onColumnClick: IColumns['onColumnClick'] = useCallback(
    (_ev: React.MouseEvent<HTMLElement, MouseEvent>, column: IColumn) => {
      setColumns((prev) =>
        prev.map((c) => {
          if (c.key === column.key) {
            return {
              ...c,
              isSortedAscending: getIsSortedAscending(
                c.isSorted,
                c.isSortedAscending
              ),
              isSorted: true,
            };
          }
          return {
            ...c,
            isSorted: false,
          };
        })
      );

      const sortConf: SortConfig = {
        attribute: column.data?.name,
        order: getSortOrder(column.isSorted, column.isSortedAscending),
        ...(isReferenceSortSupported(
          column?.data?.dataType as AttributeTypeEnum
        ) && {
          referencedTableColumnName: column?.data?.columnNameToBePicked,
        }),
      };
      setSortConfig(sortConf);
    },
    [currentPageNumber, fetchRowData, filters]
  );

  const onUserView = (name: string) => {
    setIsUserViewPanelOpen(true);
    setUserName(name);
  };

  const onDismiss = () => {
    setIsUserViewPanelOpen(false);
  };

  return (
    <TrainingMatrixVew
      selectedView={selectedView}
      updateSelectedView={updateSelectedView}
      onOneTimeAssignmentButtonClick={onOneTimeAssignmentButtonClick}
      exportFile={exportFile}
      isFilterPanelOpen={isFilterPanelOpen}
      handleFilterButtonClick={handleFilterButtonClick}
      applyFilters={applyFilters}
      customObjectAttributeDefinitions={customObjectAttributeDefinitions}
      closeFilterPanel={closeFilterPanel}
      filterableColumns={filterableColumns}
      filters={filters}
      numberOfFilters={numberOfFilters}
      sortConfig={sortConfig}
      handlePageChange={handlePageChange}
      rowData={rowData}
      isLoading={isLoading}
      currentPageNumber={currentPageNumber}
      pageLimit={pageLimit}
      totalItems={totalItems}
      columns={columns}
      groupNames={groupNames}
      menuOpen={menuOpen}
      setMenuOpen={setMenuOpen}
      isUserViewPanelOpen={isUserViewPanelOpen}
      userName={userName}
      onDismiss={onDismiss}
      resetFilterPanel={resetFilterPanel}
      setResetFilterPanel={setResetFilterPanel}
      resetColumnSizes={resetColumnSizes}
      setResetColumnSizes={setResetColumnSizes}
    />
  );
};
