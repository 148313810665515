import { GriffelStyle, makeStyles } from '@fluentui/react-components';

const breacrumbStyle: GriffelStyle = {
  color: 'white',
  height: '1lh',
  padding: '0',
  paddingInline: '0.3rem',
};

export const useBreadcrumbStyles = makeStyles({
  divider: {
    color: 'white',
  },

  moreButton: {
    color: 'white',
    height: '1lh',
  },

  breacumbButton: {
    ...breacrumbStyle,
    ':hover': {
      color: 'white',
    },
  },

  breacumbButtonHover: {
    ...breacrumbStyle,
    ':hover': {
      color: 'white',
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
});
