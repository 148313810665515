import { AssignmentStatusEnum, ROUTES } from '@celito.clients/enums';
import {
  actionColumnListViewStyles,
  IActionItem,
} from '@celito.clients/list-view-engine';
import {
  FluentIconNames,
  Icon,
  Menu,
  useBreadcrumbs,
} from '@celito.clients/shared';
import {
  Button,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from '@fluentui/react-components';
import { RowItem } from 'libs/shared/src/lib/grid-view-new/src/types';
import { useNavigate } from 'react-router';

import { ViewTypes } from '../../enums/enums';

interface ActionColumnProps {
  item: RowItem;
  view: string;
}

export const ActionColumn = ({ item, view }: ActionColumnProps) => {
  const styles = actionColumnListViewStyles();
  const navigate = useNavigate();
  const { addBreadcrumb } = useBreadcrumbs();

  const onMenuItemClick = (type: string, rowData: RowItem) => {
    if (type === 'viewTraining') {
      const url = `../${ROUTES.TAKING_ASSINGMENTS.replace(
        ':name',
        rowData.key as string
      )}`;

      navigate(
        addBreadcrumb({
          path: url,
          label: rowData.label,
        })
      );
    } else if (type === 'viewAuditLogs') {
      const url = `../list/ul_activity_log_list_view__a?recordname=${rowData.key}&objectname=training_assignment__a`;

      navigate(
        addBreadcrumb({
          path: url,
          label: rowData.label,
        })
      );
    } else if (type === 'complete') {
      const url = `../${ROUTES.DOCUMENT_VIEW}?training_assignment_name=${rowData.key}&openCompleteModal=true`;
      navigate(
        addBreadcrumb({
          path: url,
          label: rowData.label,
        })
      );
    }
  };

  const menuProps: IActionItem[] = [
    {
      label: 'Audit Logs',
      iconName: 'Document20Regular' as FluentIconNames,
      onActionClick: (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        onMenuItemClick('viewAuditLogs', item);
      },
    },
    {
      label: 'View Training',
      iconName: 'Eye20Regular' as FluentIconNames,
      onActionClick: (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        onMenuItemClick('viewTraining', item);
      },
    },
  ];

  if (
    view === ViewTypes.ASSIGNMENT_VIEW &&
    ![AssignmentStatusEnum.Completed, AssignmentStatusEnum.UnAssigned].includes(
      item.status
    )
  ) {
    menuProps.push({
      label: 'Complete',
      onActionClick: (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        onMenuItemClick('complete', item);
      },
      iconName: 'ClipboardTask20Regular' as FluentIconNames,
    });
  }

  if (menuProps.length === 0) {
    return null;
  }

  return (
    <div>
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <Button
            appearance="subtle"
            icon={<Icon iconName="MoreHorizontal24Regular" />}
            onClick={(e) => e.stopPropagation()}
          />
        </MenuTrigger>
        <MenuPopover className={styles.menuPopover}>
          <MenuList className={styles.menuList}>
            {menuProps.map((menuItem) => {
              return (
                <MenuItem
                  onClick={menuItem.onActionClick}
                  className={styles.actionMenuItem}
                  key={menuItem.label}
                >
                  <div className={styles.itemContent}>
                    <Icon iconName={menuItem.iconName as FluentIconNames} />
                    {menuItem.label}
                  </div>
                </MenuItem>
              );
            })}
          </MenuList>
        </MenuPopover>
      </Menu>
    </div>
  );
};
