import { CheckIcon, OkIcon } from '@celito.clients/assets';
import {
  DelegationObjectKeyEnum,
  FormEngineModeTypeEnum,
  ObjectEnum,
  ROUTES,
} from '@celito.clients/enums';
import {
  useActiveModule,
  useConfigureLayout,
  useObjectAttributeDefinitions,
  useUser,
} from '@celito.clients/hooks';
import { UserContext } from '@celito.clients/provider';
import { ConfirmDialog, useBreadcrumbs } from '@celito.clients/shared';
import { Role, UserTypes } from '@celito.clients/types';
import {
  formatDateInTimezone,
  getZonedBasedTime,
  raiseErrorToast,
} from '@celito.clients/utils';
import LocalStrings from 'apps/web-client/src/assets/localisation';
import { IOption } from 'libs/shared/src/lib/in-house-input-select/in-house-input-select.model';
import { isEqual, max } from 'lodash';
import React, {
  useContext,
  useDeferredValue,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router';

import { DelegateTypes } from '../delegation-list/delegation-list.model';
import { delegationService } from '../hooks/delegationService';
import {
  CreateDelegationPayload,
  DelegationFormProps,
  EditDelegationPayload,
  FormFieldsType,
} from './delegation-form.model';
import DelegationFormView from './delegation-form.view';

const initialModalProps = {
  open: false,
  type: 'confirm',
  title: '',
  description: '',
  icon: '',
  primaryButtonText: '',
  secondaryButtonText: '',
  onCancelClicked: () => {},
  onConfirmClicked: () => {},
};

const initialErrors = {
  startDate: '',
  endDate: '',
  selectedFromId: '',
  selectedToId: '',
  justification: '',
};

const initialDelegationToEdit = {
  key: -1,
  primaryUserName: '',
  delegatedUserName: '',
  from: '',
  to: '',
  startAtUtcdate: '',
  endAtUtcdate: '',
  justification: '',
  label: '',
};

const DelegationFormController: React.FC<DelegationFormProps> = ({ mode }) => {
  const activeModule = useActiveModule();
  const navigate = useNavigate();
  const { user: currentUser, isBizAdmin } = useContext(UserContext);
  const { name: recordName } = useParams();
  const { fetchAllUsers } = useUser();
  const datePickeEndrRef = useRef<HTMLInputElement>(null);
  const prevData = useRef<FormFieldsType>();
  const { popBreadcrumb } = useBreadcrumbs();
  const { getDelegateByName, createDelegation, editDelegation } =
    delegationService();

  const [endDateMin, setEndDateMin] = useState<Date | undefined>();
  const [delegationFromRole, setDelegationFromRole] = useState<Role[]>([]);
  const [allUsers, setAllUsers] = useState<UserTypes[] | []>([]);
  const [modalProps, setModalProps] = useState(initialModalProps);
  const [errors, setErrors] = useState(initialErrors);
  const [isDirty, setIsDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(
    mode === FormEngineModeTypeEnum.Edit
  );
  const [delegationToEdit, setDelegationToEdit] = useState(
    initialDelegationToEdit
  );
  const [formFields, setFormFields] = useState<FormFieldsType>({
    startDate: undefined,
    endDate: undefined,
    primaryUserName: {
      text: isBizAdmin ? '' : currentUser?.label ?? '',
      value: isBizAdmin ? '' : currentUser?.name ?? '',
    },
    delegatedUserName: {
      text: '',
      value: '',
    },
    justification: '',
  });

  const deferredFormData = useDeferredValue(formFields);
  const { data: attributes, isLoading: isObjectAttributeDefinationLoading } =
    useObjectAttributeDefinitions<DelegationObjectKeyEnum>({
      objectName: ObjectEnum.DELEGATION,
    });

  const isEdit = mode === FormEngineModeTypeEnum.Edit;
  const hasDelegationStarted =
    isEdit &&
    !!delegationToEdit?.startAtUtcdate &&
    getZonedBasedTime(delegationToEdit.startAtUtcdate) <=
      getZonedBasedTime(new Date());

  useConfigureLayout({
    pageTitle: '',
  });

  useEffect(() => {
    if (recordName) getDelegationByName(recordName);
  }, [currentUser?.name, recordName]);

  useEffect(() => {
    getAllUsers();
    prevData.current = {
      startDate: delegationToEdit.startAtUtcdate
        ? delegationToEdit.startAtUtcdate
        : undefined,
      endDate: delegationToEdit.endAtUtcdate
        ? delegationToEdit.endAtUtcdate
        : undefined,
      primaryUserName: delegationToEdit.primaryUserName
        ? {
            text: delegationToEdit.from,
            value: delegationToEdit.primaryUserName,
          }
        : {
            value: isBizAdmin ? '' : currentUser?.name ?? '',
            text: isBizAdmin ? '' : currentUser?.label ?? '',
          },
      delegatedUserName: {
        text: delegationToEdit.to,
        value: delegationToEdit.delegatedUserName,
      },
      justification: delegationToEdit?.justification,
    };
    setFormFields(prevData.current);
  }, [delegationToEdit]);

  const getDelegationByName = (recordName: string) => {
    setIsLoading(true);
    getDelegateByName(recordName, activeModule?.systemName)
      .then((resp: DelegateTypes[]) => {
        setIsLoading(false);

        resp.map(
          ({
            delegatedUserLabel,
            delegatedUserName,
            endAtUtc,
            justification,
            primaryUserLabel,
            primaryUserName,
            startAtUtc,
            label,
            name,
          }) =>
            setDelegationToEdit({
              key: name,
              primaryUserName,
              delegatedUserName,
              from: primaryUserLabel,
              to: delegatedUserLabel,
              startAtUtcdate: startAtUtc,
              endAtUtcdate: endAtUtc,
              justification,
              label,
            })
        );
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getAllUsers = async () => {
    fetchAllUsers().then((response) => {
      setAllUsers(response);
      if (delegationToEdit.primaryUserName.length > 0) {
        const filteredUsers = response.filter((user: UserTypes) => {
          return user?.name === delegationToEdit.primaryUserName;
        });
        const selectedRoles = filteredUsers?.[0]?.roles?.map((role: Role) => {
          return { name: role.name };
        });
        setDelegationFromRole(selectedRoles || []);
      }
    });
  };

  const onDateChange = (key: string, date: Date) => {
    if (key === 'startDate') {
      setFormFields((prev) => ({
        ...prev,
        startDate: date,
      }));
      setErrors((prevState) => ({
        ...prevState,
        startDate: '',
      }));
    } else {
      setFormFields((prev) => ({ ...prev, endDate: date }));
      setErrors((prevState) => ({
        ...prevState,
        endDate: '',
      }));
    }
  };

  useEffect(() => {
    setIsDirty(!isEqual(prevData.current, deferredFormData));
  }, [deferredFormData]);

  useEffect(() => {
    if (formFields?.startDate) {
      let dateMin = formFields?.startDate as Date;
      if (isEdit) {
        dateMin = max([new Date(formFields.startDate), new Date()]) as Date;
        dateMin.setDate(dateMin.getDate());
      }
      setEndDateMin(dateMin);
    }
  }, [formFields?.startDate]);

  const onDelegateSelected = (key: string, selectedUserName: IOption) => {
    if (key === 'delegateFrom') {
      setFormFields((prev) => ({
        ...prev,
        primaryUserName: selectedUserName,
        delegatedUserName: {
          text: '',
          value: '',
        },
      }));
      const filteredUsers = allUsers.filter((user: UserTypes) => {
        return user?.name === selectedUserName.value;
      });
      const selectedRoles = filteredUsers?.[0]?.roles?.map((role: Role) => {
        return { name: role.name };
      });
      setDelegationFromRole(selectedRoles || []);
      setErrors((prevState) => ({
        ...prevState,
        selectedFromId: '',
      }));
    } else {
      setFormFields((prev) => ({
        ...prev,
        delegatedUserName: selectedUserName,
      }));
      setErrors((prevState) => ({
        ...prevState,
        selectedToId: '',
      }));
    }
  };

  const createDelegate = () => {
    if (
      formFields?.primaryUserName !== undefined &&
      formFields?.delegatedUserName !== undefined &&
      formFields?.startDate !== undefined &&
      formFields?.endDate !== undefined &&
      currentUser?.name
    ) {
      const payload: CreateDelegationPayload = {
        primaryUserName: isBizAdmin
          ? formFields?.primaryUserName.value
          : currentUser?.name,
        delegatedUserName: formFields?.delegatedUserName.value,
        startAtUtc:
          formFields?.startDate &&
          formatDateInTimezone(
            formFields?.startDate,
            "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
          ),

        endAtUtc: formatDateInTimezone(
          formFields?.endDate,
          "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
        ),
        justification: formFields?.justification,
        moduleName: activeModule?.systemName,
      };

      createDelegation(payload)
        .then((resp) => {
          setIsDirty(false);
          setModalProps({
            open: true,
            type: 'success',
            title: LocalStrings.saveAlertTitle,
            description: LocalStrings.createSuccessDelegate,
            icon: 'okIcon',
            primaryButtonText: LocalStrings.greatLabel,
            secondaryButtonText: '',
            onCancelClicked: onDismissModalAndReturn,
            onConfirmClicked: onDismissModalAndReturn,
          });
        })
        .catch((_error) => {
          raiseErrorToast(_error);
        });
    }
  };

  const editdelegate = () => {
    onDismissModal();
    if (
      formFields?.delegatedUserName !== undefined &&
      currentUser?.name &&
      formFields?.startDate !== undefined &&
      formFields?.endDate !== undefined
    ) {
      const payload: EditDelegationPayload = {
        startAtUtc: !hasDelegationStarted
          ? formFields?.startDate &&
            formatDateInTimezone(
              formFields?.startDate,
              "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
            )
          : undefined,
        delegatedUserName: formFields?.delegatedUserName.value,
        endAtUtc: formatDateInTimezone(
          formFields?.endDate,
          "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
        ),
        justification: formFields?.justification,
        moduleName: activeModule?.systemName,
      };
      editDelegation(delegationToEdit.key, payload)
        .then((resp) => {
          setIsDirty(false);
          setModalProps({
            open: true,
            type: 'success',
            title: LocalStrings.saveAlertTitle,
            description: LocalStrings.saveSuccessDelegate,
            primaryButtonText: LocalStrings.greatLabel,
            secondaryButtonText: '',
            onCancelClicked: onDismissModal,
            onConfirmClicked: onDismissModalAndReturn,
            icon: 'okIcon',
          });
        })
        .catch((_error) => {
          raiseErrorToast(_error);
        });
    }
  };

  const validateForm = () => {
    const validationErrors = {
      startDate: '',
      endDate: '',
      selectedFromId: '',
      selectedToId: '',
      justification: '',
    };
    if (!formFields?.startDate) {
      validationErrors.startDate = LocalStrings.sratDateRequired;
      setErrors(validationErrors);
    }
    if (!formFields?.primaryUserName?.value) {
      validationErrors.selectedFromId = LocalStrings.delegateFromRequired;
      setErrors(validationErrors);
    }
    if (!formFields?.delegatedUserName?.value) {
      validationErrors.selectedToId = LocalStrings.delegateToRequired;
      setErrors(validationErrors);
    }
    if (!formFields?.endDate) {
      validationErrors.endDate = LocalStrings.endDateValidateString;
      setErrors(validationErrors);

      if (datePickeEndrRef.current?.value) datePickeEndrRef.current.value = '';
    }
    if (!formFields?.justification) {
      validationErrors.justification = LocalStrings.justificationValidation;
      setErrors(validationErrors);
    }
    if (
      !formFields?.startDate ||
      !formFields?.primaryUserName?.value ||
      !formFields?.delegatedUserName?.value ||
      !formFields?.endDate ||
      !formFields?.justification
    )
      return false;
    else {
      setErrors(validationErrors);
      return true;
    }
  };

  const onCreateClicked = () => {
    if (validateForm()) {
      if (isEdit) {
        openEditConfirmDialog();
        return;
      }
      createDelegate();
    }
  };

  const openEditConfirmDialog = () => {
    setModalProps({
      open: true,
      type: 'confirm',
      title: LocalStrings.confirmTitle,
      description: LocalStrings.saveConfirm,
      primaryButtonText: LocalStrings.confirmLabel,
      secondaryButtonText: LocalStrings.cancelLabel,
      onCancelClicked: onDismissModal,
      onConfirmClicked: editdelegate,
      icon: 'ckeckIcon',
    });
  };

  const onDismissModalAndReturn = () => {
    onDismissModal();
    onCancelButtonClicked();
  };

  const onDismissModal = () => {
    setModalProps({
      open: false,
      type: '',
      title: '',
      description: '',
      icon: '',
      primaryButtonText: '',
      secondaryButtonText: '',
      onCancelClicked: () => {},
      onConfirmClicked: () => {},
    });
  };

  const onInputDateChanged = (key: string, value: string) => {
    if (value === '') {
      if (key === 'startDate') {
        setFormFields((prev) => ({ ...prev, startDate: undefined }));
      } else {
        setFormFields((prev) => ({ ...prev, endDate: undefined }));
      }
    }
  };

  const onJustificationChange = (value: string) => {
    setFormFields((prev) => ({ ...prev, justification: value }));
    if (value) {
      setErrors((prev) => ({ ...prev, justification: '' }));
    }
  };

  const onCancelButtonClicked = () => {
    navigate(
      popBreadcrumb({
        path: `../${ROUTES.DELEGATION}`,
        label: '',
      })
    );
  };

  return (
    <>
      <DelegationFormView
        onDateChange={onDateChange}
        endDateMin={endDateMin}
        onDelegateSelected={onDelegateSelected}
        onCreateClicked={onCreateClicked}
        onInputDateChanged={onInputDateChanged}
        onJustificationChange={onJustificationChange}
        modalProps={modalProps}
        errors={errors}
        datePickeEndrRef={datePickeEndrRef}
        delegationFromRole={delegationFromRole}
        isDirty={isDirty}
        formFields={formFields}
        attributes={attributes}
        hasDelegationStarted={hasDelegationStarted}
        isLoading={isObjectAttributeDefinationLoading || isLoading}
        isEdit={isEdit}
        itemSelectedToEdit={delegationToEdit}
        isBizAdmin={isBizAdmin}
        currentUser={currentUser}
        onButtonClicked={onCancelButtonClicked}
      />
      {modalProps.open && (
        <ConfirmDialog
          open={modalProps.open}
          onCancelClicked={modalProps.onCancelClicked}
          onConfirmClicked={modalProps.onConfirmClicked}
          iconSrc={modalProps.icon === 'okIcon' ? OkIcon : CheckIcon}
          title={modalProps.title}
          description={modalProps.description}
          primaryButtonText={modalProps.primaryButtonText}
          secondaryButtonText={modalProps.secondaryButtonText}
        />
      )}
    </>
  );
};
export default DelegationFormController;
