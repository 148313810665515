import { LocalizationString } from '@celito.clients/assets';
import {
  AttributeTypeEnum,
  ButtonTypes,
  DateFormat,
} from '@celito.clients/enums';
import { getDocumentViewUrl, isDateValid } from '@celito.clients/utils';
import { formatDate } from 'date-fns';
import { Link } from 'react-router-dom';

import { ColumnData, RowItem } from '../../../grid-view-new/src/types';
import { CustomButton, useBreadcrumbs } from '../../../shared';
import classes from '../reference-selector.module.css';

export const getReferenceDocumentsSelectorGridColumns = (
  onItemDelete?: (item: RowItem) => void,
  isPreviewTab?: boolean,
  showDownloadButton?: boolean,
  onDownloadItem?: (item: RowItem) => void,
  onColumnClick?: ColumnData['onColumnClick']
) => {
  const referenceDocumentsSelectorGridColumns: ColumnData[] = [
    {
      name: 'Document ID',
      isResizable: true,
      key: 'label',
      maxWidth: 300,
      minWidth: 200,
      fieldName: 'label',
      onColumnClick,
      showSortIconWhenUnsorted: true,
      onRender: (item) => {
        const url = getDocumentViewUrl(item.name, item.version);

        const BreadcrumbLink = ({
          label,
          path,
        }: {
          label: string;
          path: string;
        }) => {
          const { setSingleBreadcrumb } = useBreadcrumbs();

          return (
            <Link
              to={setSingleBreadcrumb({ label, path })}
              className={classes.hyperlink}
              target="_blank"
            >
              {label}
            </Link>
          );
        };

        return <BreadcrumbLink label={item.label} path={url} />;
      },
    },
    {
      name: 'Title',
      isResizable: true,
      key: 'title',
      maxWidth: 300,
      minWidth: 150,
      fieldName: 'title',
      showTooltip: true,
      onColumnClick,
      showSortIconWhenUnsorted: true,
    },
    {
      name: 'Status',
      isResizable: true,
      key: 'document_status__a',
      maxWidth: 150,
      minWidth: 50,
      fieldName: 'documentStatus',
      onColumnClick,
      showSortIconWhenUnsorted: true,
    },
    {
      name: 'Version',
      isResizable: true,
      key: 'version',
      maxWidth: 125,
      minWidth: 100,
      fieldName: 'version',
      onColumnClick,
      showSortIconWhenUnsorted: true,
    },
    {
      name: 'Effective Date',
      isResizable: true,
      key: 'effectiveDate',
      maxWidth: 300,
      minWidth: 150,
      fieldName: 'effectiveDate',
      onColumnClick,
      showSortIconWhenUnsorted: true,
      onRender: (item) =>
        isDateValid(item?.effectiveDate as string)
          ? formatDate((item?.effectiveDate as string) || '', DateFormat.Date)
          : '-',
    },
    {
      name: 'Follow Up Due Date',
      isResizable: true,
      key: 'followUpDueDate',
      maxWidth: 300,
      minWidth: 175,
      fieldName: 'followUpDueDate',
      onColumnClick,
      showSortIconWhenUnsorted: true,
      onRender: (item) =>
        isDateValid(item?.followUpDueDate as string)
          ? formatDate((item?.followUpDueDate as string) || '', DateFormat.Date)
          : '-',
    },
    {
      name: 'Owner',
      isResizable: true,
      key: 'owner',
      maxWidth: 350,
      minWidth: 150,
      fieldName: 'owner.label',
      onColumnClick,
      showSortIconWhenUnsorted: true,
      data: {
        dataType: AttributeTypeEnum.Reference,
        columnNameToBePicked: 'label',
      },
    },
    {
      key: 'actions',
      minWidth: 75,
      maxWidth: 150,
      name: LocalizationString.ACTIONS,
      fieldName: 'actions',
      isResizable: true,
      onRender: (item?: RowItem) => {
        return (
          <div className={classes.flexDisplay}>
            <CustomButton
              as="button"
              buttonType={ButtonTypes.Destructive}
              customStyles={classes.delete_btn}
              leftIcon="Delete"
              dataTestId="button-delete-reference-document"
              title="Delete"
              onClick={isPreviewTab ? undefined : () => onItemDelete?.(item!)}
              disabled={isPreviewTab}
            />
            {showDownloadButton && (
              <CustomButton
                as="button"
                buttonType={ButtonTypes.Destructive}
                customStyles={classes.delete_btn}
                leftIcon="Download"
                title="Download"
                onClick={
                  isPreviewTab
                    ? undefined
                    : () => {
                        onDownloadItem?.(item!);
                      }
                }
                disabled={isPreviewTab}
              />
            )}
          </div>
        );
      },
    },
  ];

  return referenceDocumentsSelectorGridColumns;
};
