import { LocalizationString } from '@celito.clients/assets';
import { ButtonTypes } from '@celito.clients/enums';
import {
  CustomButton,
  GridView,
  HeaderActionButtonsContainer,
  useBreadcrumbs,
} from '@celito.clients/shared';
import { Link } from 'react-router-dom';

import { ActionsSetter } from '../../components/setter';
import Pagination from './components/pagination';
import { useController } from './controller';
import { getStyles } from './styles';

export default function DocumentTypeList() {
  const { gridViewProps, paginationProps, createRoute, showAddNewButton } =
    useController();
  const styles = getStyles();
  const { addBreadcrumb } = useBreadcrumbs();
  return (
    <>
      <HeaderActionButtonsContainer>
        {showAddNewButton && (
          <Link
            className={styles.link}
            to={addBreadcrumb({
              label: LocalizationString.ADD_NEW,
              path: createRoute,
            })}
          >
            <CustomButton
              buttonTitle={LocalizationString.ADD_NEW}
              buttonType={ButtonTypes.Transparent}
            />
          </Link>
        )}
      </HeaderActionButtonsContainer>
      <div className={styles.pageContainer}>
        <GridView {...gridViewProps} className={styles.gridView} />
        <Pagination {...paginationProps} className={styles.pagination} />
      </div>
      <ActionsSetter actions={[]} />
    </>
  );
}
