import { ROUTES } from '@celito.clients/enums';
import { useBreadcrumbs } from '@celito.clients/shared';
import { raiseErrorToast } from '@celito.clients/utils';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { getDocumentData } from '../../services/document-data';
import AdminSettingsDocumentDataView from './view-document-data.view';

type DocumentData = {
  expirationInDays: number;
  documentWatermark: string;
  effectiveHeaderMessage: string;
  effectiveFooterMessage: string;
};

const AdminSettingsDocumentDataViewController = () => {
  const { addBreadcrumb } = useBreadcrumbs();
  const [documentData, setDocumentData] = useState<DocumentData>({
    expirationInDays: 0,
    documentWatermark: '',
    effectiveHeaderMessage: '',
    effectiveFooterMessage: '',
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleOnEditClick = () => {
    navigate(
      addBreadcrumb({
        label: 'Edit',
        path: `../${ROUTES.EDIT_ADMIN_DOCUMENT_DATA}`,
      })
    );
  };

  const navigateBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    setIsLoading(true);
    getDocumentData()
      .then((res: any) => setDocumentData(res?.data?.[0] as DocumentData))
      .catch((_error) => {
        raiseErrorToast(_error);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <AdminSettingsDocumentDataView
      documentData={documentData}
      onEditClick={handleOnEditClick}
      navigateBack={navigateBack}
      isLoading={isLoading}
    />
  );
};

export default AdminSettingsDocumentDataViewController;
