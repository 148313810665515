import { Card, CardHeader } from '@fluentui/react-components';
import React from 'react';

import { createSuggestionMenuStyle } from '../suggestion-menu.modal.style';
import { TopResultCardProps } from '../suggestion-menu.model';
import { getTopResultCardHeading } from '../utils/suggestion-menu.utils';

const TopResultCard: React.FC<TopResultCardProps> = (props) => {
  const styles = createSuggestionMenuStyle();

  return (
    <Card appearance="subtle">
      <CardHeader
        header={
          <div
            className={styles.cardHeader}
            role="button"
            onClick={() => {
              props.onTopResultClickHandler?.(
                props.result.detailViewName,
                props.result.name,
                props.result.version,
                props.result.label,
                props.result.title
              );
            }}
          >
            {getTopResultCardHeading(props.result)}
          </div>
        }
      />
    </Card>
  );
};

export default TopResultCard;
