import { useLocation } from 'react-router';

interface Breadcrumb {
  label: string;
  path: string;
}

export const useBreadcrumbs = () => {
  const location = useLocation();

  const getSearchParams = (path: string) => {
    const targetUrl = new URL(path, window.location.origin);
    const currentParams = new URLSearchParams(location.search);
    const newParams = new URLSearchParams();

    const breadcrumbs = currentParams.get('breadcrumb');
    if (breadcrumbs) {
      newParams.set('breadcrumb', breadcrumbs);
    }

    targetUrl.searchParams.forEach((value, key) => {
      newParams.set(key, value);
    });

    return { url: path.split('?')[0], searchParams: newParams };
  };

  const updateBreadcrumbs = (
    searchParams: URLSearchParams,
    breadcrumbs: string[]
  ) => {
    searchParams.delete('breadcrumb');
    searchParams.append('breadcrumb', breadcrumbs.join(','));
  };

  const addBreadcrumb = ({ path, label }: Breadcrumb) => {
    const { url, searchParams } = getSearchParams(path);
    const existingBreadcrumbs = searchParams.get('breadcrumb');
    const breadcrumbs = existingBreadcrumbs
      ? existingBreadcrumbs.split(',')
      : [];
    breadcrumbs.push(label);
    updateBreadcrumbs(searchParams, breadcrumbs);
    return `${url}?${searchParams.toString()}`;
  };

  const popBreadcrumb = ({ path }: Breadcrumb) => {
    const { url, searchParams } = getSearchParams(path);
    const breadcrumbParam = searchParams.get('breadcrumb');

    if (breadcrumbParam) {
      const breadcrumbs = breadcrumbParam.split(',');
      breadcrumbs.pop();
      updateBreadcrumbs(searchParams, breadcrumbs);
    }

    return `${url}?${searchParams.toString()}`;
  };

  const replaceLastBreadcrumb = ({ path, label }: Breadcrumb) => {
    const { url, searchParams } = getSearchParams(path);
    const breadcrumbParam = searchParams.get('breadcrumb');

    if (breadcrumbParam) {
      const breadcrumbs = breadcrumbParam.split(',');
      if (breadcrumbs.length > 0) {
        breadcrumbs[breadcrumbs.length - 1] = label;
      }
      updateBreadcrumbs(searchParams, breadcrumbs);
    }

    return `${url}?${searchParams.toString()}`;
  };

  const setSingleBreadcrumb = ({ path, label }: Breadcrumb) => {
    const { url, searchParams } = getSearchParams(path);
    searchParams.set('breadcrumb', label);
    return `${url}?${searchParams.toString()}`;
  };

  const getBreadcrumbs = () => {
    const params = new URLSearchParams(location.search);
    const breadcrumbParam = params.get('breadcrumb');
    return breadcrumbParam ? breadcrumbParam.split(',') : [];
  };

  return {
    addBreadcrumb,
    setSingleBreadcrumb,
    popBreadcrumb,
    replaceLastBreadcrumb,
    breadcrumbs: getBreadcrumbs(),
  };
};
