import { LocalizationString } from '@celito.clients/assets';
import {
  AttributeTypeEnum,
  DateFormat,
  ROUTES,
  TrainingAssignmentTypeEnum,
} from '@celito.clients/enums';
import { formatDate } from '@celito.clients/utils';
import { Color } from 'libs/theme/src/lib/colors';

import { GridViewProps } from '../../../grid-view-new/src';
import { ColumnData, RowItem } from '../../../grid-view-new/src/types';
import { ActionColumn } from '../components';
import { AssignmentData, AssignmentDetail, ILCAssignmentData } from '../types';

export const getMyAssignmentsListColumnConfig = (
  trainingAssignmentType: TrainingAssignmentTypeEnum,
  onColumnClick: ColumnData['onColumnClick']
): GridViewProps['columns'] => {
  return [
    {
      key: 'name',
      minWidth: 150,
      maxWidth: 180,
      name: 'Assignment ID',
      fieldName: 'name',
      isResizable: true,
      onColumnClick,
      showSortIconWhenUnsorted: true,
    },
    {
      key: 'trainingPlanId',
      minWidth: 150,
      maxWidth: 175,
      name: 'Course ID',
      fieldName: 'trainingPlanId',
      isResizable: true,
      onColumnClick,
      showSortIconWhenUnsorted: true,
    },
    {
      key: 'title',
      minWidth: 125,
      maxWidth: 175,
      name: 'Course Name',
      fieldName: 'title',
      isResizable: true,
      onColumnClick,
      showSortIconWhenUnsorted: true,
    },
    {
      key: 'status',
      minWidth: 75,
      maxWidth: 100,
      name: 'Status',
      fieldName: 'status',
      isResizable: true,
      onColumnClick,
      showSortIconWhenUnsorted: true,
    },
    {
      key: 'dueDate',
      minWidth: 100,
      maxWidth: 125,
      name: 'Due Date',
      fieldName: 'dueDate',
      isResizable: true,
      onColumnClick,
      showSortIconWhenUnsorted: true,
    },
    {
      key: 'assignmentCompletionDate',
      minWidth: 220,
      maxWidth: 260,
      name: 'Assignment Completion Date',
      fieldName: 'assignmentCompletionDate',
      isResizable: true,
      onColumnClick,
      showSortIconWhenUnsorted: true,
    },
    {
      key: 'actions',
      minWidth: 80,
      maxWidth: 80,
      name: 'Actions',
      fieldName: 'actions',
      isResizable: true,
      onRender: (item, index, column) => <ActionColumn item={item} />,
    },
  ];
};

export const getILCAssignmentsListColumnConfig = (
  trainingAssignmentType: TrainingAssignmentTypeEnum
): GridViewProps['columns'] => {
  return [
    {
      key: 'name',
      minWidth: 100,
      maxWidth: 150,
      name: 'Name',
      fieldName: 'name',
      isResizable: true,
    },
    {
      key: 'title',
      minWidth: 125,
      maxWidth: 175,
      name: 'Title',
      fieldName: 'title',
      isResizable: true,
    },
    {
      key: 'dependantCourse',
      minWidth: 110,
      maxWidth: 160,
      name: 'Dependent Course',
      fieldName: 'dependantCourse',
      isResizable: true,
    },
    {
      key: 'status',
      minWidth: 150,
      maxWidth: 170,
      name: 'Status',
      fieldName: 'status',
      isResizable: true,
    },
    {
      key: 'dueDate',
      minWidth: 100,
      maxWidth: 125,
      name: 'Due Date',
      fieldName: 'dueDate',
      isResizable: true,
    },
    {
      key: 'assignmentCompletionDate',
      minWidth: 220,
      maxWidth: 260,
      name: 'Assignment Completion Date',
      fieldName: 'assignmentCompletionDate',
      isResizable: true,
    },
    {
      key: 'actions',
      minWidth: 80,
      maxWidth: 80,
      name: 'Actions',
      fieldName: 'actions',
      isResizable: true,
      onRender: (item, index, column) => <ActionColumn item={item} />,
    },
  ];
};

export const getMyAssignmetsRowData = (
  assignmentData: AssignmentDetail[],
  trainingAssignmentType: TrainingAssignmentTypeEnum
) => {
  const rowData: RowItem[] = [];

  assignmentData.forEach((assignment) => {
    rowData.push({
      key: assignment.name,
      name: assignment.label,
      trainingPlanId: assignment.course.label,
      title: assignment.course.title,
      dependantCourse: assignment?.dependentCourse?.label,
      status: assignment.status,
      dueDate: assignment.dueDate
        ? formatDate(assignment.dueDate, DateFormat.Date)
        : '-',
      assignmentCompletionDate: assignment.completionDate
        ? formatDate(assignment.completionDate, DateFormat.Date)
        : '-',
      assignmentName: assignment.name,
      clickable: true,
      onClickNavigateRoute: `../${ROUTES.TAKING_ASSINGMENTS.replace(
        ':name',
        assignment.name
      )}`,
    });
  });

  return rowData;
};

export const getILCAssignmetsRowData = (
  courseData: ILCAssignmentData[],
  trainingAssignmentType: TrainingAssignmentTypeEnum
) => {
  const rowData: RowItem[] = [];
  courseData.forEach((course) => {
    rowData.push({
      key: course?.course?.name,
      name: course?.course?.name,
      title: `${LocalizationString.COURSE} ${course.course.title}`,
      dependantCourse: course.course.label,
      status: course.course.status,
      dueDate: course.course.followUpDueDate
        ? formatDate(course.course.followUpDueDate, DateFormat.Date)
        : '',
      assignmentCompletionDate: course.completionDate
        ? formatDate(course.completionDate, DateFormat.Date)
        : '',
      assignmentName: course.name,
      clickable: true,
      onClickNavigateRoute: `../${ROUTES.TAKING_ASSINGMENTS.replace(
        ':name',
        course.course.name
      )}`,
    });
  });

  return rowData;
};

const getCourseRowConfig = (assignment: AssignmentDetail): RowItem => {
  const course = assignment.course;
  return {
    key: course.name,
    label: assignment.label,
    course: course.label,
    courseTitle: course.title,
    status: assignment.status,
    dueDate: assignment.dueDate
      ? formatDate(assignment.dueDate, DateFormat.Date)
      : '-',
    completionDate: assignment.completionDate
      ? formatDate(assignment.completionDate, DateFormat.Date)
      : '-',
    rowBackgroundColor: Color.blue_2100,
    assignmentName: assignment.name,
    clickable: true,
    onClickNavigateRoute: `../${ROUTES.TAKING_ASSINGMENTS.replace(
      ':name',
      assignment.name
    )}`,
    version: course.version,
  };
};

export const getAssignmentListItemsPerPage = (
  trainingAssignmentType: TrainingAssignmentTypeEnum
) => {
  if (trainingAssignmentType === TrainingAssignmentTypeEnum.MyAssignments) {
    return 10;
  }
  return 5;
};

export const getCompanyAssignmentsListColumnConfig = (
  trainingAssignmentType: TrainingAssignmentTypeEnum,
  onColumnClick: ColumnData['onColumnClick']
): GridViewProps['columns'] => {
  const columnConfig: GridViewProps['columns'] = [
    {
      key: 'user',
      minWidth: 70,
      maxWidth: 80,
      name: 'User Name',
      fieldName: 'user',
      isResizable: true,
      onColumnClick,
      showSortIconWhenUnsorted: true,
      data: {
        name: 'user',
      },
    },
    {
      key: 'course',
      minWidth: 80,
      maxWidth: 100,
      name: 'Course ID',
      fieldName: 'course',
      isResizable: true,
      onColumnClick,
      showSortIconWhenUnsorted: true,
      showTooltip: true,
      data: {
        name: 'course',
      },
    },
    {
      key: 'courseTitle',
      minWidth: 80,
      maxWidth: 100,
      name: 'Course Name',
      fieldName: 'courseTitle',
      isResizable: true,
      showTooltip: true,
      showSortIconWhenUnsorted: true,
      onColumnClick,
      data: {
        name: 'course',
        columnNameToBePicked: 'title',
        dataType: AttributeTypeEnum.Reference,
      },
    },
    {
      key: 'courseVersion',
      minWidth: 80,
      maxWidth: 100,
      name: 'Course Version',
      fieldName: 'version',
      isResizable: true,
      showTooltip: true,
      showSortIconWhenUnsorted: true,
      onColumnClick,
      data: {
        name: 'course',
        columnNameToBePicked: 'version',
        dataType: AttributeTypeEnum.Reference,
      },
    },
    {
      key: 'status',
      minWidth: 50,
      maxWidth: 60,
      name: 'Status',
      fieldName: 'status',
      isResizable: true,
      onColumnClick,
      showSortIconWhenUnsorted: true,
      showTooltip: true,
      data: {
        name: 'status',
      },
    },
    {
      key: 'dueDate',
      minWidth: 80,
      maxWidth: 90,
      name: 'Assignment Due Date',
      fieldName: 'dueDate',
      isResizable: true,
      onColumnClick,
      showSortIconWhenUnsorted: true,
      showTooltip: true,
      data: {
        name: 'dueDate',
      },
    },
    {
      key: 'assignmentCompletionDate',
      minWidth: 130,
      maxWidth: 150,
      name: 'Assignment Completion Date',
      fieldName: 'completionDate',
      isResizable: true,
      showSortIconWhenUnsorted: true,
      onColumnClick,
      data: {
        name: 'completionDate',
      },
    },
    {
      key: 'actions',
      minWidth: 80,
      maxWidth: 80,
      name: 'Actions',
      fieldName: 'actions',
      isResizable: true,
      onRender: (item, index, column) => <ActionColumn item={item} />,
    },
  ];

  return columnConfig;
};

export const getCompanyAssignmentsRowData = (
  assignmentData: AssignmentData,
  trainingAssignmentType: TrainingAssignmentTypeEnum
) => {
  const rowData: RowItem[] = [];

  Object.keys(assignmentData).forEach((user) => {
    const userName = getUserNameForUser(assignmentData[user], user);

    assignmentData[user].map((assignment: any) => {
      const courseRowData = getCourseRowConfig(assignment);
      courseRowData.categoryName = userName;
      rowData.push(courseRowData);
    });
  });

  return rowData;
};

export const getCompanyAssignmentsGroupNames = (
  assignmentData: AssignmentData
): string[] => {
  const groupNames: string[] = [];

  Object.keys(assignmentData).forEach((user) => {
    const userName = getUserNameForUser(assignmentData[user], user);
    groupNames.push(userName);
  });

  return groupNames;
};

export const getUserNameForUser = (
  userAssignments: AssignmentDetail[],
  user: any
) => {
  return userAssignments?.length > 0 ? userAssignments[0].user.label : user;
};
